/* AlertMessage.scss */
.alert-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: red; /* Red background */
  color: white !important; /* White text */
  padding: 15px 25px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999; /* Ensure it's on top of other elements */
}

.alert-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-content p {
  margin: 0;
  color: white;
  font-size: 16px;
}

.alert-content button {
  background: rgb(111, 10, 10);
  color: white !important;
  border: none;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.alert-content button:hover {
  background: lightgray;
}
