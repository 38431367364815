.ourServiceArea {
  background: #f5f5f5;
  padding: 100px 0px 40px;
  .serviceWrap {
    margin-bottom: 60px;
    overflow: hidden;
    @media (max-width: 375px) {
      margin-bottom: 40px;
    }
    .serviceIcon {
      float: left;
      border: 1px solid rgba(192, 181, 150, 0.5);
      border-radius: 50%;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      @media (max-width: 1200px) {
        margin-right: 10px;
      }
      i {
        color: #c0b596;
        transition: all 0.3s;
        &::before {
          font-size: 45px;
        }
      }
    }
    &:hover {
      .serviceIcon {
        i {
          color: #999;
        }
      }
    }
    .serviceContent {
      overflow: hidden;
      h3 {
        color: #373737;
        font-size: 24px;
        padding-bottom: 10px;
        position: relative;
        margin-bottom: 10px;
        @media (max-width: 375px) {
          font-size: 20px;
        }
        &::before {
          position: absolute;
          content: "";
          left: 0;
          bottom: 0;
          width: 30px;
          height: 2px;
          background: #c0b596;
        }
      }
      p {
        color: #777;
        margin-bottom: 0;
        text-align: justify;
      }
    }
  }
  &.ourServiceAreaStyleTwo {
    background: url(../../images/practice/1.jpg) no-repeat center center / cover;
    position: relative;
    z-index: 1;
    padding: 100px 0px 70px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(21, 26, 48, 0.66);
    }
    .sectionTitle {
      h2 {
        color: #fff;
      }
    }
    .serviceWrap {
      background: rgba(192, 181, 150, 0.3);
      margin-bottom: 30px;
      padding: 20px 15px;
      .serviceIcon {
        height: 100%;
        border: none;
        width: auto;
        position: relative;
        padding-right: 15px;
        margin: 0px;
        margin-right: 15px;
        &::before {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          width: 1px;
          height: 65px;
          background: #c0b596;
          transform: translateY(-50%);
        }
        i {
          color: #c0b596;
          &::before {
            font-size: 60px;
          }
        }
      }
      .serviceContent {
        h3 {
          font-size: 30px;
          color: #fff;
          margin-bottom: 10px;
          padding-bottom: 0;
          @media (max-width: 1100px) {
            font-size: 20px;
          }
          @media (max-width: 450px) {
            font-size: 24px;
          }
          &::before {
            display: none;
          }
        }
        p {
          font-size: 16px;
          color: #ddd;
          margin-bottom: 0;
          @media (max-width: 450px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  &.ourServiceAreaStyleThree {
    padding: 100px 0px 70px;
    .serviceWrap {
      border: 1px solid #ddd;
      text-align: center;
      padding: 50px 75px;
      margin-bottom: 30px;
      @media (max-width: 1024px) {
        padding: 50px;
      }
      .serviceIcon {
        float: none;
        margin-right: 0;
        border: none;
        margin: auto;
        margin-bottom: 15px;
        i {
          color: #c0b596;
          &::before {
            font-size: 60px;
          }
        }
      }
      .serviceContent {
        h3 {
          padding-bottom: 0;
          padding-top: 20px;
          &::before {
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            height: 1px;
            width: 65px;
          }
        }
      }
    }
  }
}
